import React from "react";

function SupportArea() {
  return (
    <section className="support-area pt-115 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-6">
            <div className="support-item">
              <div className="support-icon">
                <img
                  src={
                    require(`../../assets/img/icon/support_icon01.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="support-content">
                <h4 className="title">Skill and Expertise</h4>
                <p>We possess a wide range of skills and expertise in various areas of home maintenance,repair, and remodeling. We have practical knowledge and experience in tasks such as plumbing, electrical work, carpentry, painting, general maintenance, and more. Our proficiency allows us to handle different projects efficiently and effectively.
                  
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="support-item">
              <div className="support-icon">
                <img
                  src={
                    require(`../../assets/img/icon/support_icon02.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="Reliable and Punctual">
                <h4 className="title">Reliability and Punctuality</h4>
                <p>We are committed to delivering quality service and completing projects within the agreed-upon timeframe. We value punctuality and understand the importance of respecting our clients' time. We know that clients want a company that communicates clearly, arrives on schedule, and keeps clients informed about any changes or delays that may occur.
                  
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="support-item">
              <div className="support-icon">
                <img
                  src={
                    require(`../../assets/img/icon/support_icon03.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="support-content">
                <h4 className="title">Problem-Solving Abilities</h4>
                <p>One of the key qualities of a good handyman and remodeling company is their problem-solving skills. We can assess a situation, identify the underlying issues, and determine the most appropriate course of action to resolve the problem. We thinks critically, troubleshoot effectively, and find practical solutions to various challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SupportArea;
