import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid1 from "./MainWid1";




function Project1() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Kitchen Remodel"
        paths={[
          { name: "Home", path: "/" },
          { name: "Kitchen Remodel", path: "/project1" },
        ]}
      />
      <MainWid1 />
      <FooterOne />
    </>
  );
}

export default Project1;
