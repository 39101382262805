import React from "react";

function ServiceArea({ className }) {
  return (
    <section className={className || ""}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">What We Do</span>
              <h2 className="title tg-element-title">Our Services Areas</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                background: `url("../../assets/img/services/services_item01.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon01.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="service1">Bathroom Remodeling</a>
                </h2>
                <h2 className="number">01</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/service1">Bathroom Remodeling</a>
                </h2>
                <p>We offer complete remodeling of the bathroom. We remove your old bathroom and build a new one! Or we can remodel on parts of it.
                </p>
                <a href="service1" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".4s"
              style={{
                background: `url("../../assets/img/services/services_item02.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon02.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="service2">Kitchen Remodeling</a>
                </h2>
                <h2 className="number">02</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="service2">Kitchen Remodeling</a>
                </h2>
                <p>We can remove your old kitchen and place a new one. We can improve on certains parts of the kitchen
                </p>
                <a href="service2" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item03.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon03.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="service3">Handyman Services</a>
                </h2>
                <h2 className="number">03</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="service3">Handyman Services</a>
                </h2>
                <p>We provide any handyman services such as general repairs, plumbing, electrical work, carpentry, painting, assembly,maintenance, installation, and other odd jobs. We can do things such as fixing leaky faucets, repairing broken windows, patching drywall, and more...</p>
                <a href="service3" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
