import React from "react";

function Hero() {
  return (
    <section
      className="banner-area-three banner-two"
      style={{
        backgroundImage: `url(${require("../../assets/img/banner/h4_banner_bg.png")})`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="banner-content-three">
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                Looking For Remodeling & Good Handyman Service.
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
                Our goal is to make it as easy as possible for you to walk into
                the find like this away find with the solution that suits your
                needs.
              </p>
              <a
                href="/project"
                className="btn wow fadeInUp"
                data-wow-delay=".6s"
              >
                Discover More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
