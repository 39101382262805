import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid from "./MainWid";


function ServiceDetails() {
  return (
    <>
      <HeaderThree />
      <main>
        <InnerPageTitle
          title="Handyman Services"
          paths={[
            { name: "Home", path: "/" },
            { name: "Handyman Services", path: "/service3" },
          ]}
        />
        <MainWid />
      
      </main>
      <FooterOne />
    </>
  );
}

export default ServiceDetails;
