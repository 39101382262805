import React from "react";

function HistoryArea({ videoHandler }) {
  return (
    <section className="history-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="history-img-wrap">
              <ul className="list-wrap">
                <li>
                  <img
                    src={require("../../assets/img/images/h2_about_img02.jpg")}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require("../../assets/img/images/h2_about_img01.jpg")}
                    alt=""
                  />
                  
                </li>
                
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="history-content">
              <div className="section-title mb-20">
                <span className="sub-title">Our Company History</span>
                <h2 className="title">Providing Remodeling and Handyman services since 1998
                </h2>
              </div>
              <p>Modern Remodeling was started 26 years ago by Eugene. 
              </p>
              <div className="history-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Cutting Edge Technology 
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Remodeling and Handyman Solutions
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Modern practices
                    here
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Quick Response
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HistoryArea;
