import React from "react";
import SliderCom from "../Helpers/SliderCom";

function TestimonialArea({ className }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section
      className={`inner-testimonial-area parallax pb-120 ${className || ""}`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Our Testimonial</span>
              <h2 className="title">What Our Client Feedback</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="testimonial-inner">
              <div className="testimonial-active-two position-relative">
                <SliderCom settings={settings}>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require("../../assets/img/testimonial/aben.png")}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>As a landlord and a homeowner I need a lot of work done on my properties. This company has been my go to for anything. Whether it is painting,plumbing, or electrical. Eugene the owner of Modern Remodeling always gets the work done at very low price. I highly recommend this company for any type of work.
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Dr.Aben Veinberg</h4>
                        <span>Dentist and Landlord</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require("../../assets/img/testimonial/woman.png")}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>Modern remodeling has been a pleasure to work with. I needed various work done around my house including painting walls, flooring, and bathroom remodeling. This company did an amazing job at very low price.
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Slava Rudensky</h4>
                        <span>Homeowner and wife</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require("../../assets/img/testimonial/man2.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>I needed a handyman to help me with things around the house. I called Modern Remodeling and could not have been happier. Thank you so much!
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">John Brunt</h4>
                        <span>Los Angeles Homeowner</span>
                      </div>
                    </div>
                  </div>
                </SliderCom>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-avatar-wrap">
         
        </div>
      </div>
    </section>
  );
}

export default TestimonialArea;
