import React from "react";

function AboutArea() {
  return (
    <section
      className="about-area-two parallax about-bg"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/about_bg.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap-two">
              <ul className="list-wrap">
                <li>
                  <div
                    className="year-experience-wrap wow fadeInDown"
                    data-wow-delay=".2s"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon03.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <h2 className="title">
                      26 Years <span>Experience</span>
                    </h2>
                  </div>
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/images/h2_about_img01.jpg`)}
                    alt=""
                    className="wow fadeInLeft"
                    data-wow-delay=".4s"
                  />
                </li>
              </ul>
              <ul className="list-wrap">
                <li>
                  <img
                    src={require(`../../assets/img/images/h2_about_img02.jpg`)}
                    alt=""
                    className="wow fadeInRight"
                    data-wow-delay=".2s"
                  />
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/images/h2_about_img03.jpg`)}
                    alt=""
                    className="wow fadeInUp"
                    data-wow-delay=".4s"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-two">
              <div className="section-title mb-30">
                <span className="sub-title">About Our Company</span>
                <h2 className="title">We’re Committed to Home Renovation and Handyman Excellence</h2>
              </div>
              <p>Our commitment is to transform homes into beautiful and functional spaces through renovation. We enhance aesthetics and functionality while meeting clients' unique needs. A well-designed home improves quality of life and property value. We take pride in helping homeowners realize their vision. Additionally, we excel in providing a wide range of handyman services, from repairs to complex installations. Our skilled professionals handle plumbing, electrical, carpentry, painting, and more with precision and efficiency. We prioritize high-quality workmanship, attention to detail, and superior customer service, believing that every project deserves exceptional craftsmanship.</p>
              <div className="about-list-two">
                <ul className="list-wrap">
                  <li className="list-item">
                   
                  </li>
                </ul>
              </div>
              <a href="/about" className="btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about-shape-wrap">
        <ul className="list-wrap">
          <li>
            <img
              src={require(`../../assets/img/images/about_shape01.png`)}
              className="layer"
              data-depth="0.3"
              alt=""
            />
          </li>
          <li>
            <img
              src={require(`../../assets/img/images/about_shape02.png`)}
              className="layer"
              data-depth="0.3"
              alt=""
            />
          </li>
          <li>
            <img
              src={require(`../../assets/img/images/about_shape03.png`)}
              className="layer"
              data-depth="0.2"
              alt=""
            />
          </li>
        </ul>
      </div>
    </section>
  );
}

export default AboutArea;
