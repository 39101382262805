import React, {useState} from "react";
const image1 = require('./gazebo1.JPG')
const image2 = require('./gazebo2.JPG')
const image3 = require('./gazebo3.JPG')
const image4 = require('./gazebo4.JPG')
const image5 = require('./gazebo5.JPG')





import "./MainWid10.css"

function MainWid10() {

var images = [image1,image2,image3,image4,image5]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Assembly of Gazebo</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>Gazebo was assembled and placed in corect spot.</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid10;
