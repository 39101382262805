import React, {useState} from "react";
const image1 = require('./kitchen1.JPG')
const image2 = require('./kitchen2.JPG')
const image3 = require('./kitchen3.jpg')
const image4 = require('./kitchen4.JPG')
const image5 = require('./kitchen5.JPG')
const image6 = require('./kitchen6.JPG')
const image7 = require('./kitchen7.JPG')
const image8 = require('./kitchen8.JPG')
const image9 = require('./kitchen9.JPG')
const image10 = require('./kitchen10.JPG')
const image11 = require('./kitchen11.JPG')

import "./MainWid1.css"

function MainWid1() {

//var images = ["./kitchen1.JPG", "./kitchen2.JPG", "./kitchen3.jpg"]
var images = [image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Complete Kitchen Remodel</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>The old kitchen was completely removed and the new kitchen that was bought from IKEA was assembled and installed. The old wooden floor was removed and new tile was installed. The walls were also painted. Tiles on the wall by the stove and else where were installed. This project included other things including demolishing a wall and creating an arch. It involved demolishing a bar, painting the living room, and installing an electronic gas fireplace.</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid1;
