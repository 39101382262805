import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid7 from "./MainWid7";




function Project7() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Door Repair and Installation"
        paths={[
          { name: "Home", path: "/" },
          { name: "Door Repair and Installation", path: "/project7" },
        ]}
      />
      <MainWid7 />
      <FooterOne />
    </>
  );
}

export default Project7;
