import React from "react";

function FooterOne() {
  return (
    <footer>
      <div
        className="footer-area footer-bg"
        style={{
          backgroundImage: `url(${require("../../../assets/img/bg/footer_bg.jpg")})`,
        }}
      >
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h2 className="fw-title">About Us</h2>
                  <div className="footer-content">
                    <p>We provide exceptional remodeling and handyman services. 
                      
                      
                    </p>
                    
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Our Services</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a href="/service2">
                          <i className="fas fa-angle-double-right"></i>Kitchen Remodeling
                        </a>
                      </li>
                      <li>
                        <a href="/service1">
                          <i className="fas fa-angle-double-right"></i>Bathroom Remodeling
                        </a>
                      </li>
                      <li>
                        <a href="/service3">
                          <i className="fas fa-angle-double-right"></i>Handyman Services
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Quick Links</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                    <li>
                        <a href="/Projects">
                          <i className="fas fa-angle-double-right"></i>Lastest Projects
                    
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Contact
                          Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-12">
                  <div className="logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/w_logo.png`)}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="footer-contact">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <span>Phone No</span>
                      <a href="tel:13233698335">1 (323) 369-8335</a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-6">
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p>© Copyright {new Date().getFullYear()}. All Right Reserved</p>
                </div>
              </div>
              <div className="col-md-6">
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
