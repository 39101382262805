import React from "react";
import SliderCom from "../Helpers/SliderCom";
const stuccoimg = require("../Project/Project6/Stucco2.jpg")

function ProjectArea() {
  const settingSlider = {
    dots: true,
    infinite: true,
    speed:2000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div
      className="area-bg-four"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/area_bg04.jpg")})`,
      }}
    >
      <section className="project-area-four">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-60">
                <span className="sub-title">Latest Projects</span>
                <h2 className="title">Explore Our Latest Projects</h2>
              </div>
            </div>
          </div>
          <div className="row project-active-two">
            <SliderCom settings={settingSlider}>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project1">
                      <img
                        src={require(`../../assets/img/project/h4_project_img01.jpg`)}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project1">Complete Kitchen Remodel</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project-details">Remodel</a>
                        </li>
                        <li>
                          <a href="/project-details">Kitchen</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project1" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project2">
                      <img
                        src={require("../Project/Project2/bathroom2.JPG")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project2">Complete Bathroom Remodel</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project2">Remodel</a>
                        </li>
                        <li>
                          <a href="/project2">Bathroom</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project2" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project3">
                      <img
                        src={require("../Project/Project3/privacy1.JPG")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project3">Privacy Wall Assembly and Installation</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project3">Installation</a>
                        </li>
                        <li>
                          <a href="/project3">Privacy Wall</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project3" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project4">
                      <img style={{height:"320px"}}
                        src={require("../Project/Project4/bathroomz10.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project4">Bathroom Remodeling</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project4">Remodeling</a>
                        </li>
                        <li>
                          <a href="/project4">Bathroom</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project4" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project5">
                      <img style={{height:"320px"}}
                        src={require("../Project/Project5/Fireplace2.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project5">Fireplace Remodeling</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project5">Remodeling</a>
                        </li>
                        <li>
                          <a href="/project5">Fireplace</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project5" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project5">
                      <img style={{height:"320px"}}
                        src={stuccoimg}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project6">Stucco Removal</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project6">Remodeling</a>
                        </li>
                        <li>
                          <a href="/project6">Stucco Removal</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project6" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project6">
                      <img style={{height:"320px"}}
                        src={require("../Project/Project7/door2.JPG")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project7">Fix and replace doors</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project7">Remodeling and Repair</a>
                        </li>
                        <li>
                          <a href="/project7">Doors</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project7" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project7">
                      <img style={{height:"320px"}}
                        src={require("../Project/Project8/hangingthing.JPG")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project8">Install hanging chair</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project8">Installation</a>
                        </li>
                        <li>
                          <a href="/project8">Furniture</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project8" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project9">
                      <img style={{height:"320px"}}
                        src={require("../Project/Painting1.JPG")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project9">Painting of Bathroom</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project9">Remodeling</a>
                        </li>
                        <li>
                          <a href="/project9">Painting</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project9" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <a href="/project9">
                      <img style={{height:"320px"}}
                        src={require("../Project/Project10/gazebo5.JPG")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <a href="/project10">Assembly of Gazebo</a>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <a href="/project10">Remodeling</a>
                        </li>
                        <li>
                          <a href="/project10">Assembly</a>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <a href="/project9" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  
                </div>
              </div>
            </SliderCom>
          </div>
        </div>
      </section>

    </div>
  );
}

export default ProjectArea;
