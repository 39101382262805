import React, {useState} from "react";
const image1 = require('./bathroom1.JPG')
const image2 = require('./bathroom2.JPG')
const image3 = require('./bathroom3.JPG')
const image4 = require('./bathroom4.JPG')
const image5 = require('./bathroom5.JPG')
const image6 = require('./bathroom6.JPG')
const image7 = require('./bathroom7.JPG')
const image8 = require('./bathroom8.JPG')
const image9 = require('./bathroom9.jpg')
const image10 = require('./bathroom10.jpg')
const image11 = require('./bathroom11.jpg')
const image12 = require('./bathroom12.jpg')
const image13 = require('./bathroom13.jpg')
const image14= require('./bathroom14.jpg')
const image15 = require('./bathroom15.jpg')
const image16 = require('./bathroom16.jpg')
const image17 = require('./bathroom17.jpg')
const image18 = require('./bathroom18.jpg')
const image19 = require('./bathroom19.jpg')
const image20 = require('./bathroom20.jpg')


import "./MainWid2.css"

function MainWid2() {


var images = [image9,image10,image11,image12,image13,image14,image15,image16,image17,image18,image19,image20,image1,image2,image3,image4,image5,image6,image7,image8]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Complete Kitchen Remodel</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>The old bathroom was removed and a new bathroom was installed</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid2;
