import React from "react";

function MainWid() {
  return (
    <section className="inner-project-area pt-115 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Our Projects</span>
              <h2 className="title">Our Latest Projects</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project1">
                  <img
                    src={require("./Project1/kitchen1.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Remodel</span>
                <h2 className="title">
                  <a href="/project1">Complete Kitchen Remodel</a>
                </h2>
                <a href="/project1" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="project2">
                  <img
                    src={require("./Project2/bathroom1.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Remodel</span>
                <h2 className="title">
                  <a href="/project2">Complete Bathroom Remodel</a>
                </h2>
                <a href="/project2" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project3">
                  <img
                    src={require("./Project3/privacy1.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Installation</span>
                <h2 className="title">
                  <a href="/project3">Installation of Privacy Fence</a>
                </h2>
                <a href="/project3" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project4">
                  <img style={{height:"375px"}}
                    src={require("./Project4/bathroomz10.jpg")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Remodel</span>
                <h2 className="title">
                  <a href="/project4">Complete Bathroom Remodel</a>
                </h2>
                <a href="/project4" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project5">
                  <img style={{height:"375px"}}
                    src={require("./Project5/Fireplace2.jpg")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Remodel</span>
                <h2 className="title">
                  <a href="/project5">Fireplace Remodel</a>
                </h2>
                <a href="/project5" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project6">
                  <img style={{height:"375px"}}
                    src={require("./Project6/Stucco1.jpg")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Remodel</span>
                <h2 className="title">
                  <a href="/project6">Stucco Removal</a>
                </h2>
                <a href="/project6" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project7">
                  <img
                    src={require("./Project7/door2.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Repair and Replace</span>
                <h2 className="title">
                  <a href="/project7">Doors replacement and repair</a>
                </h2>
                <a href="/project7" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project8">
                  <img
                    src={require("./Project8/hangingthing.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Installation</span>
                <h2 className="title">
                  <a href="/project8">Installation of Hanging Chair</a>
                </h2>
                <a href="/project8" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project9">
                  <img
                    src={require("./Painting1.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Remodeling</span>
                <h2 className="title">
                  <a href="/project9">Painting of Bathroom</a>
                </h2>
                <a href="/project9" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/project10">
                  <img
                    src={require("./Project10/gazebo5.JPG")}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                <span>Installation</span>
                <h2 className="title">
                  <a href="/project10">Assembly of Gazebo</a>
                </h2>
                <a href="/project10" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
