import React, {useState} from "react";
const image1 = require('./privacy1.JPG')
const image2 = require('./privacy2.JPG')
const image3 = require('./privacy3.JPG')
import "./MainWid3.css"

function MainWid3() {

var images = [image1,image2,image3]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Privacy Wall Assembly and Installation</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>The bush that covered the wall was removed. A privacy fence was assembled and installed</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid3;
