import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import MainWid from "./MainWid";
import FooterOne from "../Layouts/Footers/FooterOne";

function index() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Contact Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "Contact", path: "/contact" },
        ]}
      />
      <MainWid />
      <FooterOne />
    </>
  );
}

export default index;
