import React from "react";

function MainWid() {
  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Contact With Us</h2>
              <p>Send us a message and we' ll respond as soon as possible</p>
              <form method="POST" action="https://formspree.io/f/xbjnpbbl" className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                      name="First Name"
                        id="firstName"
                        type="text"
                        placeholder="First Name*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                       name="Last Name"
                        id="lastName"
                        type="text"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                       name="Email"
                        id="email"
                        type="email"
                        placeholder="Email Address*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                       name="Phone Number"
                        id="phone"
                        type="text"
                        placeholder="Phone Number*"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <input id="subject" type="text" placeholder="Subject" />
                </div>
                <div className="form-grp">
                  <textarea
                   name="Message"
                    id="message"
                    placeholder="Your Message here"
                  ></textarea>
                </div>
                <button className="btn" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we' ll respond as soon as possible</p>
              <ul className="list-wrap">
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <a href="tel:13233698335">1 (323) 369-8335</a>
                      </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:Domratchev40@gmail.com">Domratchev40@gmail.com</a>
                      </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="content">
                      <p>
                        12203 Magnolia blvd <br /> Valley Village, CA, 91607
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div id="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.261237042431!2d-118.4014960259531!3d34.16523801175827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2960ed31e40f5%3A0xced0c049ef4d4da!2s12203%20Magnolia%20Blvd%2C%20Valley%20Village%2C%20CA%2091607!5e0!3m2!1sen!2sus!4v1712956583579!5m2!1sen!2sus" title="map" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
