import React from "react";

function MainWid() {
  return (
    <section className="services-details-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img
                  src={require("../../Project/Project1/kitchen1.JPG")}
                  alt=""
                />
              </div>
              <div className="services-details-content">
                <h2 className="title">
                  
                </h2>
                <p>Modern Remodeling can do a complete tear down of your old kitchen and installation and assembly of new. We can also repair any part of the kitchen that is broken.</p>

               
                
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <aside className="services-sidebar">
              <div className="services-widget">
                <h4 className="widget-title">Our All Service</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    <li>
                      <a href="/service2">
                        Kitchen Remodeling
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/service1">
                        Bathroom Remodeling<i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="service3">
                        Handyman Services<i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div
                className="services-widget widget-bg"
                data-background="assets/img/services/sw_bg.jpg"
              >
                <h4 className="widget-title">Get a free quote</h4>
                <form method="POST" action="https://formspree.io/f/xbjnpbbl" className="sidebar-form">
                  <div className="form-grp">
                    <input name="name" id="name" type="text" placeholder="Your Name" />
                  </div>
                  <div className="form-grp">
                    <input
                    name="email address"
                      id="email"
                      type="text"
                      placeholder="Your Email Address"
                    />
                  </div>
                  <div className="form-grp">
                    <textarea
                    name="message"
                      id="message"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-two">
                    Contact Us
                  </button>
                </form>
              </div>
             
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
