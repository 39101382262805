import { Route, Routes } from "react-router-dom";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Error from "./Components/Error";
import Services from "./Components/Services";
import ServiceDetails from "./Components/Services/ServiceDetails";
import HomeFour from "./Components/HomeFour";
import Project1 from "./Components/Project/Project1";
import Project2 from "./Components/Project/Project2";
import Project3 from "./Components/Project/Project3";
import Project4 from "./Components/Project/Project4";
import Project5 from "./Components/Project/Project5";
import Project6 from "./Components/Project/Project6";
import Project7 from "./Components/Project/Project7";
import Project8 from "./Components/Project/Project8";
import Project9 from "./Components/Project/Project9/index.jsx";
import Project10 from "./Components/Project/Project10/index.jsx";
import Project from "./Components/Project/index.jsx"
import Service1 from "./Components/Services/Service1/index.jsx";
import Service2 from "./Components/Services/Service2/index.jsx";
import Service3 from "./Components/Services/Service3/index.jsx";

function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeFour />} />
      <Route exact path="/home-two" element={<HomeFour />} />
      <Route exact path="/home-three" element={<HomeFour />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/error" element={<Error />} />
      <Route exact path="projects" element={<Project/>} />
      <Route exact path="project1" element={<Project1/>} />
      <Route exact path="project2" element={<Project2/>} />
      <Route exact path="project3" element={<Project3/>} />
      <Route exact path="project4" element={<Project4/>} />
      <Route exact path="project5" element={<Project5/>} />
      <Route exact path="project6" element={<Project6/>} />
      <Route exact path="project7" element={<Project7/>} />
      <Route exact path="project8" element={<Project8/>} />
      <Route exact path="project9" element={<Project9/>} />
      <Route exact path="project10" element={<Project10/>} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/Service1" element={<Service1 />} />
      <Route exact path="/Service2" element={<Service2 />} />
      <Route exact path="/Service3" element={<Service3 />} />
      <Route
        exact
        path="/services/services-details"
        element={<ServiceDetails />}
      />
    </Routes>
  );
}

export default Routers;
