import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid5 from "./MainWid5";




function Project5() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Fireplace Remodel"
        paths={[
          { name: "Home", path: "/" },
          { name: "Fireplace Remodel", path: "/project5" },
        ]}
      />
      <MainWid5 />
      <FooterOne />
    </>
  );
}

export default Project5;
