import React, {useState} from "react";
const image1 = require('./hangingthing.JPG')





import "./MainWid8.css"

function MainWid8() {

var images = [image1]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Installation of swinging chair</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>Swinging chair was installed on the ceiling</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid8;
