import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid10 from "./MainWid10";




function Project10() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Assembly of Gazebo"
        paths={[
          { name: "Home", path: "/" },
          { name: "Gazebo Installation", path: "/project10" },
        ]}
      />
      <MainWid10 />
      <FooterOne />
    </>
  );
}

export default Project10;
