import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid2 from "./MainWid2";




function Project2() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Kitchen Remodel"
        paths={[
          { name: "Home", path: "/" },
          { name: "Kitchen Remodel", path: "/project2" },
        ]}
      />
      <MainWid2 />
      <FooterOne />
    </>
  );
}

export default Project2;
