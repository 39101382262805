import React, {useState} from "react";
const image1 = require('./bathroomz1.jpg')
const image2 = require('./bathroomz2.jpg')
const image3 = require('./bathroomz3.jpg')
const image4 = require('./bathroomz4.jpg')
const image5 = require('./bathroomz5.jpg')
const image6 = require('./bathroomz6.jpg')
const image7 = require('./bathroomz7.jpg')
const image8 = require('./bathroomz8.jpg')
const image9 = require('./bathroomz9.jpg')
const image10 = require('./bathroomz10.jpg')
const image11 = require('./bathroomz11.jpg')
const image12 = require('./bathroomz12.jpg')



import "./MainWid4.css"

function MainWid4() {

var images = [image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Complete Bathroom Remodel</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>Old bathroom was removed and new bathroom was installed</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid4;
