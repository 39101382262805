import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid6 from "./MainWid6";




function Project6() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Stucco Removal"
        paths={[
          { name: "Home", path: "/" },
          { name: "Stucco Removal", path: "/project6" },
        ]}
      />
      <MainWid6 />
      <FooterOne />
    </>
  );
}

export default Project6;
