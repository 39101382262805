import React from "react";

function WorkArea() {
  return (
    <section className="work-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Working Plan</span>
              <h2 className="title">Remodeling and Handyman Working Process</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img01.png`)}
                  alt=""
                />
                <h4 className="number">01</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Plan for work</h2>
                <p>
                  Discuss what type of work needs to be done
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img02.png`)}
                  alt=""
                />
                <h4 className="number">02</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Schedule Estimate</h2>
                <p>Physically visit the place and do analysis on site to give exact estimate
                  
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img03.png`)}
                  alt=""
                />
                <h4 className="number">03</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Begin Work</h2>
                <p>The discussed work is started
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img04.png`)}
                  alt=""
                />
                <h4 className="number">04</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Enjoy result</h2>
                <p>After the work is complete now it is time to enjoy the result
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkArea;
