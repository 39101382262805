import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid9 from "./MainWid9";




function Project9() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Painting of Bathroom"
        paths={[
          { name: "Home", path: "/" },
          { name: "Bathroom painting", path: "/project9" },
        ]}
      />
      <MainWid9 />
      <FooterOne />
    </>
  );
}

export default Project9;
