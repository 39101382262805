import React from "react";
import { useState,useRef } from "react";
import axios from "axios"

function NewsLetterArea() {

  const [btndisabled, setbtndisabled] = useState(false);
  var nameref = useRef(null)
  var phoneref = useRef(null) 

/*var submitmessage = () =>{
  
  setbtndisabled(true)
  var postdata = {
    "from": {
      "email": "danvienberg@gmail.com",
      "name": "Example Order Confirmation"
    },
    "reply_to": {
      "email": "danveinberg@gmail.com",
      "name": "Example Customer Service Team"
    },
    "subject": "Someone is interested",
    "content": [
      {
        "type": "text/html",
        "value": "<p>" + nameref.current.value + " is interested please call him back at " + phoneref.current.value + "</p"
      }
    ],
    
  }
 /*axios.post("https://api.sendgrid.com/v3/send",postdata,{ headers: {"Access-Control-Allow-Origin":"*","Authorization" : "Bearer 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"} }
  ).then(response => {
    // Handle the response data
    setbtndisabled(false)
  })

  axios.post("https://www.magnesium-powder.com/sendemail.php",{name:nameref.current.value,phone:nameref.current.value})
  .then(response =>{
    console.log(response.data)
    setbtndisabled(false)
  })
 
}*/

  return (
    <section
      className="newsletter-area jarallax newsletter-bg"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/newsletter_bg.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5">
            <div className="newsletter-content">
              <div className="section-title white-title-two tg-heading-subheading animation-style3">
                <span className="sub-title tg-element-title">
                  Quality Service Contact From
                </span>
                <h2 className="title tg-element-title">
                  Need any home remodeling or handyman service?
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <div className="newsletter-form">
              <form method="POST" action="https://formspree.io/f/xbjnpbbl">
                <div className="form-grp">
                  <input name="name" ref={nameref} type="text" placeholder="Name*" />
                </div>
                <div className="form-grp">
                  <input name="phone" ref={phoneref} type="text" placeholder="Phone*" />
                </div>
                <button type="submit" className="btn btn-two">
                  Submit Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsLetterArea;
