import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid3 from "./MainWid3";




function Project3() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Privacy Wall Installation"
        paths={[
          { name: "Home", path: "/" },
          { name: "Privacy Wall Installation", path: "/project3" },
        ]}
      />
      <MainWid3 />
      <FooterOne />
    </>
  );
}

export default Project3;
