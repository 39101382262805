import React, {useState} from "react";
const image1 = require('./Stucco1.jpg')
const image2 = require('./Stucco2.jpg')
const image3 = require('./Stucco3.jpg')
const image4 = require('./Stucco4.jpg')




import "./MainWid6.css"

function MainWid6() {

var images = [image1,image2,image3,image4]
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
       <div className="row text-center">
        <div className="col">
          <h4>Remove Stucco from Ceiling</h4>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
          <p style={{color:'black'}}>Stucco was removed from ceiling and the ceiling was painted. This was done in several rooms including master bedroom, living room, and entrance.</p>
        </div>
       </div>
       <div className="row text-center">
        <div className="col">
 
       <div className="carousel">
      <button className="carousel_btn" onClick={handlePrev}>&lt;</button>
      <img className="img_carousel" src={images[currentImage]} alt="carousel-image" />
      <button className="carousel_btn" onClick={handleNext}>&gt;</button>
    </div>
    
        </div>
       </div>
       </div>
       
      
      
    </section>
  );
}

export default MainWid6;
