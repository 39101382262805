import React from "react";

function MainWid() {
  return (
    <section className="services-details-area pt-120 pb-120">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="services-details-wrap">
            <div className="services-details-thumb">
              <img
                src={require("../../../assets/img/banner/h4_banner_bg.png")}
                alt=""
              />
            </div>
            <div className="services-details-content">
              <h2 className="title">
                
              </h2>
              <p>
              A handyman is a skilled professional who offers a wide range of services to help with various household and property maintenance tasks. They are typically experienced in multiple trades and can handle different projects, repairs, and installations around the home or workplace. Here are some common services that a handyman may offer:

General Repairs: A handyman can assist with minor repairs and fixes, such as fixing leaky faucets, patching drywall, repairing squeaky doors, replacing broken tiles, or fixing faulty electrical switches.

Carpentry: They can handle basic carpentry tasks like installing shelves, building custom cabinets, repairing or replacing damaged furniture, and constructing small structures like decks or fences.

Plumbing: A handyman can address plumbing issues like unclogging drains, fixing leaky pipes, installing new fixtures (e.g., toilets, sinks), or replacing faucets and showerheads.

Electrical Work: They can handle simple electrical tasks like installing light fixtures, replacing switches and outlets, troubleshooting minor electrical problems, or installing ceiling fans.

Painting and Decorating: Handyman services often include interior and exterior painting, wall patching, wallpaper installation, or minor touch-ups to help refresh the appearance of your property.

Assembly: They can assist with assembling furniture, installing shelves, hanging pictures or mirrors, or mounting TVs on walls.

Maintenance Services: A handyman can perform routine maintenance tasks, such as gutter cleaning, pressure washing, lawn and garden maintenance, or seasonal upkeep.

Door and Window Repairs: They can repair or replace damaged doors, windows, screens, or hinges, ensuring proper functionality and security.

Home Safety and Security: Handyman services may include installing smoke detectors, carbon monoxide detectors, security cameras, or door/window locks to enhance the safety and security of your home.

Odd Jobs: Handymen are often willing to tackle miscellaneous tasks, such as furniture moving, organizing storage spaces, hanging curtains or blinds, or even helping with small renovation projects.
              </p>
             
              
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <aside className="services-sidebar">
            <div className="services-widget">
              <h4 className="widget-title">Our All Service</h4>
              <div className="our-services-list">
                <ul className="list-wrap">
                  <li>
                    <a href="/service2">
                      Kitchen Remodeling
                      <i className="fas fa-arrow-right"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/service1">
                      Bathroom Remodeling<i className="fas fa-arrow-right"></i>
                    </a>
                  </li>
                  <li>
                    <a href="service3">
                      Handyman Services<i className="fas fa-arrow-right"></i>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div
              className="services-widget widget-bg"
              data-background="assets/img/services/sw_bg.jpg"
            >
              <h4 className="widget-title">Get a free quote</h4>
              <form method="POST" action="https://formspree.io/f/xbjnpbbl" className="sidebar-form">
                <div className="form-grp">
                  <input name="name" id="name" type="text" placeholder="Your Name" />
                </div>
                <div className="form-grp">
                  <input
                  name="email address"
                    id="email"
                    type="text"
                    placeholder="Your Email Address"
                  />
                </div>
                <div className="form-grp">
                  <textarea
                  name="message"
                    id="message"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-two">
                  Contact Us
                </button>
              </form>
            </div>
           
          </aside>
        </div>
      </div>
    </div>
  </section>
  );
}

export default MainWid;
